import React from 'react';
import { Typography, Link } from '@mui/material';
import { Theme } from '@emotion/react';
import ColorScheme from 'src/styles/colors';

interface ILinkedProps extends IProps {
	to: string;
}

interface IProps {
    sx?: Theme;
    variant?: "inherit" | "button" | "overline" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2";
}

const LogoStyle = {
	fontFamily: 'Supercell Magic',
	fontWeight: 600,
	margin: '0px',
	userSelect: 'none',
	color: ColorScheme.vars.palette.text.primary,
};

export const LinkedLogo: React.FunctionComponent<ILinkedProps> = (props) => (
	<Link href={props.to} draggable={'false'}><Logo {...props} /></Link>
);

export const Logo: React.FunctionComponent<IProps> = (props) => (
	<Typography sx={{ ...LogoStyle, ...props.sx }} variant={props.variant? props.variant : 'h1'}>ClashBot</Typography>
);

export default Logo;
