import React, { useEffect } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { LegalPath } from './legal';
import { Add } from '@mui/icons-material';

const rand = Math.random();

const ContainerStyle = { 
	display: 'flex', 
	flexDirection: 'column',
	justifyContent: 'center', 
	alignItems: 'center'
};

const LogoStyle = {
	fontFamily: 'Supercell Magic',
	fontWeight: 600, // SemiBold
	userSelect: 'none', // Disable text selection
	cursor: 'default', // Change cursor to default
	fontSize: {
		xs: '4.5rem',  // for extra-small screens
		s: '5rem',
		sm: '8rem',  // for small screens
		md: '12rem', // for medium screens
		lg: '15rem'  // for large screens and up
	},
	textAlign: 'center',
	wordBreak: 'break-word',
	paddingBottom: '25px',
};

const DiscordInviteLink = 'https://discord.com/oauth2/authorize?client_id=1266359105275297792&scope=bot%20applications.commands&permissions=67111938';

export const HomePath = '/';
export const Home: React.FunctionComponent = () => {
	const [mousePosition, setMousePosition] = React.useState({ x: 50, y: 50 });
	const backgrounds = [
		`url(/assets/banner1.jpg) ${mousePosition.x}% ${mousePosition.y}% / 125%`,
		`url(/assets/banner2.jpg) ${mousePosition.x}% ${mousePosition.y}% / 125%`,
		`url(/assets/banner3.jpeg) ${mousePosition.x}% ${mousePosition.y}% / 125%`,
		`url(/assets/banner4.jpeg) ${mousePosition.x}% ${mousePosition.y}% / 125%`,
	];

	useEffect(() => {
		const handleMouseMove = (event: MouseEvent) => {
			const x = Math.max(1, Math.min(100, (event.clientX / window.innerWidth) * 100));
			const y = Math.max(1, Math.min(100, (event.clientY / window.innerHeight) * 100));
			setMousePosition({ x, y });
		};

		window.addEventListener('mousemove', handleMouseMove);
		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);

	return (
		<Container sx={ContainerStyle}>
			<Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
				<Typography sx={{
					...LogoStyle,
					background: backgrounds[Math.floor(rand * backgrounds.length)],
					WebkitBackgroundClip: 'text',
					WebkitTextFillColor: 'transparent',
				}} variant={'h1'}>ClashBot</Typography>
				<Button sx={{ backgroundColor: '#5865F2' }} startIcon={<Add />} size={'large'} variant={'contained'} href={DiscordInviteLink}>Add to Discord</Button>
			</Box>
			<Box sx={{ position: 'fixed', right: '25px', bottom: '25px' }}>
				<Button href={LegalPath}>Legal</Button>
			</Box>
		</Container>
	);
};

export default Home;
