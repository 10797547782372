import React from 'react';
import { Container, Breadcrumbs, Link, Typography, Box } from '@mui/material';
import Logo from 'src/components/Logo';
import { HomePath } from '../home';
import { LegalPath } from '.';

interface ISectionProps {
	title: string;
	children: React.ReactNode;
}

const ContainerStyle = {
	display: 'block',
	boxSizing: 'border-box',
	width: '100%',
	marginLeft: 'auto',
	marginRight: 'auto',
	paddingTop: '16px',
	paddingBottom: '75px',
	maxWidth: '77px',
};

const WrapperStyle = {
	paddingTop: '25px',
	
	'h2': {
		gap: '6px',
	},

	'h4': {
		margin: '24px 0 10px',
	},

	'h5': {
		margin: '15px 10px',

		'&:first-of-type': {
			marginTop: '0',
		},
	},

	'a': {
		fontSize: '1.25rem',
		lineHeight: '1.5',
		color: '#006BD6',
		textDecorationColor: 'rgba(0, 115, 230, 0.4)',
	}
};

const Section: React.FunctionComponent<ISectionProps> = ({ title, children }) => (
	<>
		<Typography variant={'h4'}>{title}</Typography>
		{children}
	</>
);

const SectionText: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
	<Typography sx={{ marginBottom: '0.75rem' }} component={'p'} variant={'subtitle1'} color={'text.secondary'}>{children}</Typography>
);

export const PrivacyPolicyPath = '/legal/privacy-policy';
export const PrivacyPolicy: React.FunctionComponent = () => (
	<Container sx={{ paddingTop: '10px' }}>
		<Logo />
		<Container sx={ContainerStyle}>
			<Breadcrumbs sx={{ userSelect: 'none' }}>
					<Link underline={'hover'} color={'inherit'} href={HomePath}>Home</Link>
					<Link underline={'hover'} color={'inherit'} href={LegalPath}>Legal</Link>
					<Typography color={'text.primary'}>Privacy Policy</Typography>
			</Breadcrumbs>
			<Typography sx={{ gap: '6px' }} variant={'h3'}>Privacy Policy</Typography>
			<Box sx={WrapperStyle}>
				<Section title={'Personal Information ClashBot Collects About You'}>
					<SectionText>
						We obtain information about you through the means discussed below when we provide the ClashBot services.
						Please note that we need certain types of information so that we can provide the ClashBot services to you.
						If you do not provide us with such information, or ask us to delete it, you may no longer be able to access or use the ClashBot services.
					</SectionText>
					<SectionText>
						User provided information: We collect and store information when you link third party services with ClashBot.
					</SectionText>
					<SectionText>
						Information from other sources: We may obtain information about you from third parties and sources other than ClashBot.
						For example, we may obtain additional information from advertisers, games or services you use, or social media networks (Such as Clash of Clans, Discord, Android or Apple) for which you have approved our access.
					</SectionText>
				</Section>
				<Section title={'How ClashBot Uses Your Information'}>
					<SectionText>
						ClashBot uses such information to operate, maintain, enhance, provide and develop of the features, functionality, and services (new or existing) found on the ClashBot services;
						This information is used to provide security for our website, products, software, and services; improve the user experience with ClashBot; prevent fraud and abuse; and understand the usage trends of our users.
					</SectionText>
					<SectionText>
						ClashBot does NOT use your information for advertising or marketing purposes nor sell or provide information collected about you to any third parties.
						The only reason we may share your information with third parties is if we receive specific information that is requested by law or if we have a good-faith belief that such action is necessary to protect our rights, property, safety of ourselves or our users.
						Unless prevented by law, we will attempt to notify you about your data being shared with third parties for any reason.
					</SectionText>
				</Section>
			</Box>
		</Container>
	</Container>
);

export default PrivacyPolicy;
