import { createTheme } from '@mui/material';
import { ComponentThemes } from './components';

export const Theme = createTheme({
	palette: {},
	typography: {},
	components: ComponentThemes,
});

export default Theme;
