import React from 'react';
import { Container, Breadcrumbs, Link, Typography, Box } from '@mui/material';
import Logo from 'src/components/Logo';
import { HomePath } from '../home';
import { LegalPath } from '.';
import { PrivacyPolicyPath } from './privacy';

interface ISectionProps {
	title: string;
	children: React.ReactNode;
}

const ContainerStyle = {
	display: 'block',
	boxSizing: 'border-box',
	width: '100%',
	marginLeft: 'auto',
	marginRight: 'auto',
	paddingTop: '16px',
	paddingBottom: '75px',
	maxWidth: '77px',
};

const WrapperStyle = {
	paddingTop: '25px',
	
	'h2': {
		gap: '6px',
	},

	'h4': {
		margin: '24px 0 10px',
	},

	'h5': {
		margin: '15px 10px',

		'&:first-of-type': {
			marginTop: '0',
		},
	},

	'a': {
		fontSize: '1.25rem',
		lineHeight: '1.5',
		color: '#006BD6',
		textDecorationColor: 'rgba(0, 115, 230, 0.4)',
	}
};

const SectionLinkStyle = {
	margin: 0,
	padding: 0,
	border: 0,
	fontSize: '1rem!important',
	fontWeight: 400,
	font: 'inherit',
	verticalAlign: 'baseline',
	color: 'color-mix(in srgb, #9fa6ad, #5865F2 50%)!important',
	textDecoration: 'none',

	'&:hover': {
		color: 'color-mix(in srgb, #9fa6ad, #5865F2 75%)!important',
	}
};

const Section: React.FunctionComponent<ISectionProps> = ({ title, children }) => (
	<>
		<Typography variant={'h4'}>{title}</Typography>
		{children}
	</>
);

const SectionText: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
	<Typography sx={{ marginBottom: '0.75rem' }} component={'p'} variant={'subtitle1'} color={'text.secondary'}>{children}</Typography>
);

export const TermsOfServicePath = '/legal/terms-of-service';
export const TermsOfService: React.FunctionComponent = () => (
	<Container sx={{ paddingTop: '10px' }}>
		<Logo />
		<Container sx={ContainerStyle}>
			<Breadcrumbs sx={{ userSelect: 'none' }}>
					<Link underline={'hover'} color={'inherit'} href={HomePath}>Home</Link>
					<Link underline={'hover'} color={'inherit'} href={LegalPath}>Legal</Link>
					<Typography color={'text.primary'}>Terms of Service</Typography>
			</Breadcrumbs>
			<Typography sx={{ gap: '6px' }} variant={'h3'}>Terms of Service</Typography>
			<Box sx={WrapperStyle}>
				<Section title={'Intruduction'}>
					<SectionText>
						PLEASE READ THESE TERMS OF SERVICE CAREFULLY. THIS IS A BINDING CONTRACT.
						Welcome to the services operated by ClashBot consisting of the website available at {`${window.location.protocol}//${window.location.hostname}`}, and its network of software applications, and any other products or services offered by ClashBot.
					</SectionText>
					<SectionText>
						When using the ClashBot services, you will be subject to the ClashBot Terms of Service and additional guidelines or rules that are posted on the ClashBot services, made available to you, or disclosed to you in connection with specific services or features that may be offered.
						ClashBot may offer you paid services, which are subject to the party which is responsible for the purchase of the service or subscription as well as any additional terms or conditions that are disclosed to you in connection with such services.
						All such terms and guidelines are incorporated into these Terms of Service by reference.
					</SectionText>
					<SectionText>
						The Terms of Service apply whether you are a user that voluntarily provides information to the ClashBot service, or are a user of a website, software application, or other product which makes use of ClashBot.
						You acknowledge that by providing information voluntarily, access, or otherwise utilize ClashBot services, you are entering into a legally binding agreement between you and ClashBot regarding the use of ClashBot services.
						You acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you do not agree to these Terms of Service, do not access or utilize ClashBot services in any way. ClashBot services allows you to access as well as delete all data related to you upon request.
					</SectionText>
				</Section>

				<Section title={'Use of ClashBot by Minors and Blocked Persons'}>
					<SectionText>
						The ClashBot services are not available to users under the age of 13 years of age.
						If you are between the ages of 13 and the age of legal majority in your jurisdiction of residence, you may only use the ClashBot services under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Service.
					</SectionText>
					<SectionText>
						The ClashBot services are also not available to users who have been deemed to be abusive to our systems in any way we see fit or to any persons barred from receiving them under the laws of the United States (such as its export and re-export restrictions) or applicable laws in any other jurisdiction.
					</SectionText>
					<SectionText>
						BY PROVIDING INFORMATION TO, ACCESSING, OR OTHERWISE USING THE CLASHBOT SERVICES, YOU REPRESENT THAT YOU ARE AT LEAST 13 YEARS OF AGE, THAT YOUR PARENT OR LEGAL GUARDIAN AGREES TO BE BOUND BY THESE TERMS OF SERVICE IF YOU ARE BETWEEN 13 AND THE AGE OF LEGAL MAJORITY IN YOUR JURISDICTION OF RESIDENCE, AND THAT YOU HAVE NOT BEEN PREVIOUSLY REMOVED FROM AND ARE NOT PROHIBITED FROM RECEIVING THE CLASHBOT SERVICES.
					</SectionText>
				</Section>

				<Section title={'Privacy Notice'}>
					<SectionText>
						ClashBot is committed to protecting the privacy of its users. Please see our <Link sx={SectionLinkStyle} href={PrivacyPolicyPath}>Privacy Notice</Link> for information relating to how we collect, use, and disclose your personal information.
					</SectionText>
				</Section>

				<Section title={'Refunds'}>
					<SectionText>
						All payments and subscriptions towards ClashBot are not handled by ClashBot directly and as such, refunds are at the discretion of the party where the payment or subscription was made.
					</SectionText>
				</Section>

				<Section title={'Taxes'}>
					<SectionText>
						You are responsible for any applicable national, state, or local sales or use taxes, value added taxes ("VAT"), excise taxes, gross receipts taxes, or similar taxes or fees payable in connection with your purchase of Ancillary Products and Services.
						As the purchaser of Ancillary Products or Services for another party as a gift, taxes, or other fees on that transaction will be calculated based on your country of residence.
						If you do not pay such sales or other tax or fee on a transaction, you will be responsible for such taxes or fees in the event that they are later determined to be payable on such sale.
						For tax purposes, the purchase of Ancillary Products and Services is for personal, non-commercial, private use.
					</SectionText>
				</Section>
			</Box>
		</Container>
	</Container>
);

export default TermsOfService;
