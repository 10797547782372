import React from 'react';
import { Container, Box, Typography, Link, Breadcrumbs } from '@mui/material';
import { Logo } from '../../components/Logo';
import { HomePath } from '../home';
import { TermsOfServicePath } from './terms';
import { PrivacyPolicyPath } from './privacy';

interface ISectionProps {
	title: string;
	href: string;
	children: React.ReactNode;
}

const LegalContainerStyle = {
	width: '100%',
	marginLeft: '0',
	marginRight: 'auto',
	boxSizing: 'border-box',
	display: 'block',
	paddingTop: '16px',
	maxWidth: '775px',

	'@media (min-width: 1200px)': {
		maxWidth: '775px',
	},

	'@media (min-width: 600px)': {
		paddingLeft: '24px',
		paddingRight: '24px',
	},
};

const BoxStyle = {
	paddingTop: '25px',
	
	'h2': {
		gap: '6px',
	},

	'h4': {
		margin: '24px 0 10px',
	},

	'h5': {
		margin: '15px 10px',

		'&:first-of-type': {
			marginTop: '0',
		},
	},

	'a': {
		fontSize: '1.25rem',
		lineHeight: '1.5',
		color: 'color-mix(in srgb, #9fa6ad, #5865F2 50%)!important',
		textDecoration: 'none',

		'&:hover': {
			color: 'color-mix(in srgb, #9fa6ad, #5865F2 75%)!important',
			textDecorationColor: 'rgba(0, 115, 230, 0.4)',
			textDecoration: 'underline',
		}
	}
};

const Section: React.FunctionComponent<ISectionProps> = (props) => (
	<Typography variant={'h5'}>
		<Link href={props.href}>{props.title}</Link>
		<Typography variant={'subtitle1'} color={'text.secondary'}>{props.children}</Typography>
	</Typography>
);

export const LegalPath = '/legal';
export const Legal: React.FunctionComponent = () => (
	<Container sx={{ paddingTop: '10px', userSelect: 'none' }}>
		<Logo />
		<Container sx={LegalContainerStyle}>
			<Breadcrumbs>
				<Link underline={'hover'} color={'inherit'} href={HomePath}>Home</Link>
				<Typography color={'text.primary'}>Legal</Typography>
			</Breadcrumbs>
			<Box sx={BoxStyle}>
				<Typography variant={'h3'}>Legal Information</Typography>
				<Typography sx={{ marginBottom: '25px' }} variant={'subtitle1'}>This page contains links to important legal information for ClashBot users.</Typography>

				<Section title={'Terms of Service'} href={TermsOfServicePath}>
					This describes the rules and guidelines regarding the use of our services.
				</Section>
				<Section title={'Privacy Policy'} href={PrivacyPolicyPath}>
					This describes our policy and procedures regarding the collection, use, and disclosure of information received by using our services.
				</Section>
			</Box>
		</Container>
	</Container>
);

export default Legal;
