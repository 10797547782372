import { createBrowserRouter, RouterProvider, RouteObject } from 'react-router-dom';

import Home, { HomePath } from './home';
import Legal, { LegalPath } from './legal';
import TermsOfService, { TermsOfServicePath } from './legal/terms';
import PrivacyPolicy, { PrivacyPolicyPath } from './legal/privacy';

export interface Route {
	path: string;
	element: JSX.Element;
}

export const Routes: Route[] = [
	{ path: HomePath, element: <Home /> },
	{ path: LegalPath, element: <Legal /> },
	{ path: TermsOfServicePath, element: <TermsOfService /> },
	{ path: PrivacyPolicyPath, element: <PrivacyPolicy /> },
];

function getRoutes(routes: Route[]): RouteObject[] {
	let data: RouteObject[] = [];
	for(let route of routes) {
		data.push(route);
	}
	return data;
}

export const PageRouter: React.FunctionComponent = () => {
	const routes = getRoutes(Routes);
	return <RouterProvider router={createBrowserRouter(routes)} />
}

export default PageRouter;